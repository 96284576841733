import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

interface IProp {
    location?: any
}

class NotFoundPage extends React.Component<IProp> {
    public render() {
        return (
            <Layout location={this.props.location}>
                <SEO title="404" />
                <h1>Not Found</h1>
                <p>
                    You just hit a route that doesn&#39;t exist... the sadness.
                </p>
            </Layout>
        )
    }
}

export default NotFoundPage
